import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import NavBar from "../HomeScreen/NavBar";
import Footer1 from "../HomeScreen/Footer1";
import Footer2 from "../HomeScreen/Footer2";
import Footer3 from "../HomeScreen/Footer3";
import Footer4 from "../HomeScreen/Footer4";
import { useSelector } from "react-redux";
import { IoMdDownload } from "react-icons/io";
import ImagePath from "../../assets/images";
import { Document, Page } from "react-pdf";
import html2canvas from "html2canvas";
import {
  clearForm,
  getIsPayment,
  getPdf,
  getPreview,
  getResume,
  getTemp,
  getUserId1,
  loginUserAt,
  loginUserCcode,
  loginUserEmail,
  loginUserName,
  loginUserPhone,
  setResume,
} from "../../utils/localStorage";
import Razorpay from "razorpay";
import {
  createOrderService,
  getBiodata,
  successOrderService,
} from "../../services/ApiService";
import toast, { Toaster } from "react-hot-toast";
import jsPdf from "jspdf";
import FooterNew from "../HomeScreen/FooterNew";

const PreviewPage = () => {
  const [TemplateHTML, setTemplateHTML] = useState(null);
  const [TemplateId, setTemplateId] = useState(null);
  const [isPayment, setIsPayment] = useState(null);

  const [user, setUser] = React.useState(loginUserName());
  const [email, setEmail] = React.useState(loginUserEmail());
  const [userAt, setUserAt] = React.useState(loginUserAt());
  const [code, setCode] = React.useState(loginUserCcode());
  const [phone, setPhone] = React.useState(loginUserPhone());

  const submitTemp = getPdf();
  const [payment, setPayment] = useState(getIsPayment());
  // const TemplateHTML = useSelector((store) => store.reducer.template);
  // const TemplateHTML = getPreview();
  const isFormSubmit = useSelector((store) => store.reducer.submit);
  const bioData = useSelector((store) => store.reducer.biodataId);
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfUrl, setPdfUrl] = useState();
  console.log(payment);
  const paymentNumber = Number(payment);
  console.log(typeof paymentNumber);
  console.log(isPayment);
  console.log(typeof isPayment);

  const [initialValues1, setInitialValues1] = useState({
    user_id: getUserId1(),
    resume_id: getResume(),
    templates_id: null,
  });

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    const fetchBiodata = async () => {
      try {
        const user_id = getPreview();
        console.log("USERID", user_id);
        const response = await getBiodata(user_id);
        console.log("Response:", response);
        setPdfUrl(response.pdfPath);
        const Temp = response.pdfHTML;
        const id = response.templates_id;
        const paymentId = response.is_payment;
        setIsPayment(paymentId);

        console.log("id", id);
        setInitialValues1((prevState) => ({
          ...prevState,
          templates_id: id,
        }));
        setTemplateHTML(Temp);
        setTemplateId(id);
        console.log("temp_id", TemplateId);
        // setResume(TemplateId);
        submitTemp = null;
        console.log(TemplateHTML);
      } catch (error) {
        console.error("Error fetching biodata:", error);
      }
    };
    fetchBiodata();

    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  // console.log(submitTemp);
  // console.log(TemplateHTML);

  useEffect(() => {
    // Get the body element
    const body = document.querySelector("body");

    // Modify the padding
    body.style.padding = "0";
  }, []);

  const handleFileUpload = async () => {
    // if (!TemplateHTML && !submitTemp) return;
    // const element = !submitTemp && TemplateHTML ? document.getElementById("card") : submitTemp ? document.getElementById("card1") : null;
    // // Define PDF page size and orientation
    // const pageWidth = element.offsetWidth; // Adjust as needed
    // const pageHeight = element.offsetHeight; // Adjust as needed
    // const orientation = pageWidth > pageHeight ? "l" : "p"; // Landscape or Portrait based on dimensions
    // // Create new jsPDF instance
    // let doc = new jsPdf(orientation, "pt", [pageWidth, pageHeight]);
    // // Convert HTML element to canvas
    // html2canvas(element).then((canvas) => {
    //     const imageData = canvas.toDataURL("image/*");
    //     // Add background image to the first page of the PDF
    //     doc.addImage(
    //         imageData,
    //         "JPEG",
    //         0,
    //         0,
    //         pageWidth,
    //         pageHeight,
    //         undefined, // alias (ignored)
    //         "SLOW" // image compression ("SLOW" for better quality)
    //     );
    //     // Save the PDF
    //     doc.save("biodata.pdf");
    // });

    // const Link = document.createElement("a");
    // Link.href = pdfUrl;
    // Link.target = "_blank";
    // Link.download = "biodata.pdf";
    // document.body.appendChild(Link);
    // Link.click();
    // document.body.removeChild(Link);

    try {
      const response = await fetch(pdfUrl, {
        mode: "no-cors",
        headers: {
          Accept: "application/pdf",
          // Add any auth headers if needed
          // 'Authorization': `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch the file");
      }

      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = "biodata.pdf";

      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  const [initialValues, setInitialValues] = useState({
    user_id: getUserId1(),
    resume_id: getResume(),
    templates_id: getTemp(),
  });

  const makePayment = async () => {
    console.log("Initial Values:", initialValues);

    try {
      const response = await createOrderService(initialValues);

      if (response.status === "success") {
        console.log("Payment successfully:", response);
        toast.success(response.message, {
          duration: 5000, // Duration in milliseconds
          position: "top-right", // Position of the toast
        });

        // Extract necessary values from the response
        const { id, razorpay_id, amount, currency, razorpay_amount_due } =
          response.data;
        const key_id = response.key;

        // Ensure the necessary fields are present
        if (!razorpay_id) {
          throw new Error("Order ID is missing in the response");
        }
        if (!key_id) {
          throw new Error("Key is missing in the response");
        }

        const amountInPaisa = Math.round(razorpay_amount_due * 100);

        // Create options object for Razorpay
        var options = {
          description: "Make my biodata template fee",
          currency: currency,
          key: key_id,
          amount: response.data.amount,
          name: "Make My Biodata",
          // "image": ImagePath.Icon,
          order_id: razorpay_id, // Replace this with an order_id created using Orders API.
          prefill: {
            email: email,
            contact: code + phone,
            name: user,
          },
          theme: { color: "#EC0673" },
          handler: async function (response) {
            // This handler will be called after a successful payment
            let orderData = {
              id: id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
            };
            console.log("Payment successful:", orderData);
            try {
              const payRes = await successOrderService(orderData);
              if (payRes.status === "success") {
                console.log("Payment is done:", payRes);
                clearForm();
                setPayment(payRes.is_payment);
                toast.success(payRes.message, {
                  duration: 5000, // Duration in milliseconds
                  position: "top-right", // Position of the toast
                });
              } else {
                toast.error(payRes.message, {
                  duration: 5000, // Duration in milliseconds
                  position: "top-right", // Position of the toast
                });
              }
            } catch (error) {
              console.log("Error:", error);
              toast.error(error, {
                duration: 5000, // Duration in milliseconds
                position: "top-right", // Position of the toast
              });
            }
          },
        };

        // Log the options object
        console.log("Razorpay Options:", options);

        // Ensure the Razorpay script is loaded before initializing
        if (window.Razorpay) {
          const rzp1 = new window.Razorpay(options);

          rzp1.on("payment.success", function (response) {
            let orderData = {
              id: id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
            };
            console.log("Payment successful:", orderData);
          });

          rzp1.on("payment.error", function (response) {
            console.error("Payment failed:", response.error);
          });

          rzp1.open();
        } else {
          console.error("Razorpay SDK not loaded");
        }
      } else {
        console.log("Error:", response);
        toast.error(response.message, {
          duration: 5000, // Duration in milliseconds
          position: "top-right", // Position of the toast
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const makePayment1 = async () => {
    console.log("Initial Values:", initialValues1);

    try {
      const response = await createOrderService(initialValues1);

      if (response.status === "success") {
        console.log("Payment successfully:", response);
        toast.success(response.message, {
          duration: 5000, // Duration in milliseconds
          position: "top-right", // Position of the toast
        });

        // Extract necessary values from the response
        const { id, razorpay_id, amount, currency, razorpay_amount_due } =
          response.data;
        const key_id = response.key;

        // Ensure the necessary fields are present
        if (!razorpay_id) {
          throw new Error("Order ID is missing in the response");
        }
        if (!key_id) {
          throw new Error("Key is missing in the response");
        }

        const amt = Number(amount);
        const amountInPaisa = Math.round(amt * 100);
        console.log("Amount in Paisa:", amountInPaisa);

        // Create options object for Razorpay
        var options = {
          amount: response.data.amount,
          description: "Make my biodata template fee",
          currency: currency,
          key: key_id,
          name: "Make My Biodata",
          // "image": ImagePath.Icon,
          order_id: razorpay_id, // Replace this with an order_id created using Orders API.
          prefill: {
            email: email,
            contact: code + phone,
            name: user,
          },
          theme: { color: "#EC0673" },
          method: {
            upi: true,
            card: true,
            netbanking: true,
            wallet: true,
            qr: true, // Enable QR code payment
          },
          handler: async function (response) {
            // This handler will be called after a successful payment
            let orderData = {
              id: id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
            };
            console.log("Payment successful:", orderData);
            try {
              const payRes = await successOrderService(orderData);
              if (payRes.status === "success") {
                console.log("Payment is done:", payRes);
                clearForm();
                setIsPayment(payRes.is_payment);
                toast.success(payRes.message, {
                  duration: 5000, // Duration in milliseconds
                  position: "top-right", // Position of the toast
                });
              } else {
                toast.error(payRes.message, {
                  duration: 5000, // Duration in milliseconds
                  position: "top-right", // Position of the toast
                });
              }
            } catch (error) {
              console.log("Error:", error);
              toast.error(error, {
                duration: 5000, // Duration in milliseconds
                position: "top-right", // Position of the toast
              });
            }
          },
        };

        // Log the options object
        console.log("Razorpay Options:", options);

        // Ensure the Razorpay script is loaded before initializing
        if (window.Razorpay) {
          const rzp1 = new window.Razorpay(options);

          rzp1.on("payment.success", function (response) {
            let orderData = {
              id: id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
            };
            console.log("Payment successful:", orderData);
          });

          rzp1.on("payment.error", function (response) {
            console.error("Payment failed:", response.error);
          });

          rzp1.open();
        } else {
          console.error("Razorpay SDK not loaded");
        }
      } else {
        console.log("Error:", response);
        toast.error(response.message, {
          duration: 5000, // Duration in milliseconds
          position: "top-right", // Position of the toast
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <Grid
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: "5.4%",
        alignItems: "center",
        backgroundColor: "lightgrey",
        // maxWidth: "1400px",
        margin: "0 auto", // Center the content horizontally
      }}
    >
      <Grid container style={{ backgroundColor: "", height: "96px" }}></Grid>
      <NavBar />
      <Toaster toastOptions={{ duration: 4000 }} />
      {!submitTemp && TemplateHTML ? (
        <Grid
          style={{
            width: "100%",
            display: "flex",
            maxWidth: "1400px",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Card
            style={{
              width: "100%",
              backgroundColor: "lightgrey",
              boxShadow: "none",
            }}
          >
            <Grid>
              <Grid
                id="card"
                style={{
                  padding:
                    window.innerWidth > 1400 ? "20px 0" : "20px 7% 20px 7%",
                }}
                dangerouslySetInnerHTML={{ __html: TemplateHTML }}
              ></Grid>
            </Grid>
            {/* {isPayment === 0 && (    
            <div style={{  bottom: "20px", right: "20px", padding : "3rem 0px", display: "flex", justifyContent: "center" }}>
          <Button
          onClick={makePayment1}
          style={{
            background:
              "linear-gradient(to right,  #f35491, #fe5356,  #ee7724)",
            width: "10rem",
            height: "50px",
            zIndex: 100
          }}
         
        >
          <Typography style={{color: "white", textTransform: "none"}}>
          Make Payment
          </Typography>
        </Button>
        </div>
          )} */}

            {(() => {
              if (isPayment === 0) {
                return (
                  <div
                    style={{
                      bottom: "20px",
                      right: "20px",
                      padding: "3rem 0px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      onClick={makePayment1}
                      sx={{
                        background: "#EC0673",
                        width: "10rem",
                        height: "50px",
                        zIndex: 100,
                        color: "white",
                        "&:hover": {
                          color: "black",
                          backgroundColor: "rgb(255, 210, 235)",
                        },
                      }}
                    >
                      <Typography
                        style={{ textTransform: "none", fontFamily: "Avenir" }}
                      >
                        Make Payment
                      </Typography>
                    </Button>
                  </div>
                );
              } else {
                return (
                  <div
                    style={{
                      position: "fixed",
                      bottom: "110px",
                      right: "40px",
                      zIndex: 2000,
                    }}
                  >
                    <Button
                      style={{
                        background: "rgb(255, 210, 235)",
                        borderRadius: "50%",
                        width: "55px",
                        height: "65px",
                        zIndex: 20000,
                        opacity: 1,
                      }}
                      onClick={handleFileUpload}
                    >
                      <IoMdDownload
                        style={{ fontSize: 35, color: "#EC0673" }}
                      />
                    </Button>
                  </div>
                );
              }
            })()}
          </Card>
        </Grid>
      ) : submitTemp ? (
        <Grid
          style={{
            width: "100%",
            display: "flex",
            maxWidth: "1400px",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Card
            style={{
              width: "100%",
              backgroundColor: "lightgrey",
              boxShadow: "none",
            }}
          >
            <Grid>
              <div
                id="card1"
                style={{
                  padding:
                    window.innerWidth > 1400 ? "20px 0" : "20px 7% 20px 7%",
                }}
                dangerouslySetInnerHTML={{ __html: submitTemp }}
              ></div>
            </Grid>

            {(() => {
              if (paymentNumber === 0) {
                return (
                  <div
                    style={{
                      bottom: "20px",
                      right: "20px",
                      padding: "3rem 0px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      onClick={makePayment}
                      sx={{
                        background: "#EC0673",
                        width: "10rem",
                        height: "50px",
                        zIndex: 100,
                        color: "white",
                        "&:hover": {
                          color: "black",
                          backgroundColor: "rgb(255, 210, 235)",
                        },
                      }}
                    >
                      <Typography
                        style={{ textTransform: "none", fontFamily: "Avenir" }}
                      >
                        Make Payment
                      </Typography>
                    </Button>
                  </div>
                );
              } else {
                return (
                  <div
                    style={{
                      position: "fixed",
                      bottom: "110px",
                      right: "40px",
                      zIndex: 2000,
                    }}
                  >
                    <Button
                      style={{
                        background: "rgb(255, 210, 235)",
                        borderRadius: "50%",
                        width: "55px",
                        height: "65px",
                        zIndex: 2990,
                        opacity: 1,
                      }}
                      onClick={handleFileUpload}
                    >
                      <IoMdDownload
                        style={{ fontSize: 35, color: "#EC0673" }}
                      />
                    </Button>
                  </div>
                );
              }
            })()}
          </Card>
        </Grid>
      ) : null}

      {/* <div style={{ position: "fixed", bottom: "20px", right: "20px", zIndex: 100 }}>
{(() => {
  if (isPayment === 1 || payment === 1) {
    return (
      <div style={{ position: "fixed", bottom: "20px", right: "20px", zIndex: 100 }}>
        <Button
          style={{
            background: "linear-gradient(to right, #f35491, #fe5356, #ee7724)",
            borderRadius: "50%",
            width: "35px",
            height: "50px",
            zIndex: 100
          }}
          onClick={handleFileUpload}
        >
          <IoMdDownload style={{ fontSize: 25, color: "white" }} />
        </Button>
      </div>
    );
  } else {
    return null;
  }
})()}
</div> */}

      <Footer1 />
      {/* <Footer2 />
<Footer4 /> */}
      <FooterNew />
      <Footer3 />
    </Grid>
    // </Grid>
  );
};

export default PreviewPage;
